var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buyers"},[_c('div',{staticClass:"ls-card"},[_c('div',{staticClass:"m-t-24"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.pager.lists,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"scene_name","label":"通知类型","min-width":"180"}}),_c('el-table-column',{attrs:{"label":"系统消息","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.system_notice.status == 0
                                    ? 'danger'
                                    : 'success',"effect":"plain"}},[_vm._v(" "+_vm._s(!scope.row.system_notice.status ? '关闭' : '启用')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"短信通知","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.sms_notice.status == 0
                                    ? 'danger'
                                    : 'success',"effect":"plain"}},[_vm._v(" "+_vm._s(!scope.row.sms_notice.status ? '关闭' : '启用')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"微信模板","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.oa_notice.status == 0
                                    ? 'danger'
                                    : 'success',"effect":"plain"}},[_vm._v(" "+_vm._s(!scope.row.oa_notice.status ? '关闭' : '启用')+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"login_time","label":"微信小程序","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.mnp_notice.status == 0
                                    ? 'danger'
                                    : 'success',"effect":"plain"}},[_vm._v(" "+_vm._s(!scope.row.mnp_notice.status ? '关闭' : '启用')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.goSetting(scope.row)}}},[_vm._v("设置 ")])]}}])})],1),_c('div',{staticClass:"m-t-24 pagination"},[_c('ls-pagination',{on:{"change":_vm.getNoticeList},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }