





















































































































import { Component, Vue } from 'vue-property-decorator'
import { apiNoticeData } from '@/api/application/sms'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsPagination
    }
})
export default class Buyers extends Vue {
    pager: RequestPaging = new RequestPaging()

    getNoticeList() {
        this.pager
            .request({
                callback: apiNoticeData,
                params: { recipient: 2 }
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    goSetting(row: any) {
        this.$router.push({
            path: '/sms/buyers/setting',
            query: {
                id: row.id
            }
        })
    }

    created() {
        this.getNoticeList()
    }
}
